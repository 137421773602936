/*
 * datedomain2many-widget.less - Styling for the date domain list widget
 */
.form-datedomain2many-menu {
  text-align: center;

  // Centre the date selector
  > .input-group {
    display: inline-block;
    margin-bottom: 1em;
  }

  // Ensure the buttons and date are on the same line at the same height
  .form-date {
    display: inline-block;
    height: 1.5em;
    width: 9em;

    .icon-input {
      right: 40px;
    }
  }
}
